import "./egw.scss";

function EGW() {
    return <div className="egw">
        <div className="container">
            <h1>Epic Games Weekly</h1>
            <p id="buttons"><a
                    href="https://discord.com/oauth2/authorize?client_id=777612933643108392&scope=bot&permissions=150528"
                    target="_blank" rel="noopener noreferrer">Invite the bot</a>
                <a href="https://discord.gg/wk9yE3bRnu" target="_blank" rel="noopener noreferrer">Support</a>
            </p>
            <p>No ads, no spam, no nonsense. Only what you're looking for; a clean, informative
                embed with Epic Game's weekly free games.</p>
            <img src="https://f.lieuweberg.com/icENE2.png" alt="weekly embed screenshot" />
        </div>
        <div>
            <h3>Getting started</h3>
            <p>If you want the weekly embed sent to a channel automatically;</p>
            <ol>
                <li>Invite the bot using <a
                        href="https://discord.com/oauth2/authorize?client_id=777612933643108392&amp;scope=bot&amp;permissions=150528">this
                        link</a> or the invite button above.</li>
                <li>Navigate to the channel in your server where you want the bot to send the weekly free games embed.
                </li>
                <li>Type <code>/set &lt;channel&gt;</code>.</li>
            </ol>
            <p>If you just want to view the weekly free games manually, you can run <code>/weekly</code>.</p>
            <h3>Help</h3>
            <table>
                <thead>
                    <tr>
                        <th>Command</th>
                        <th>Explanation</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><code>/set [channel]</code></td>
                        <td>Set the channel to send the weekly free games in.</td>
                    </tr>
                    <tr>
                        <td><code>/weekly</code></td>
                        <td>Replies with the current weekly free games.</td>
                    </tr>
                    <tr>
                        <td><code>/info</code></td>
                        <td>Sends an embed with some bot statistics.</td>
                    </tr>
                    <tr>
                        <td><code>/help</code></td>
                        <td>Returns what you're viewing right now; the help menu.</td>
                    </tr>
                    <tr>
                        <td><code>/ping</code></td>
                        <td>Replies with some latency statistics.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
}

export default EGW;