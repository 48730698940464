import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

import Home from "./Home";
import EGW from "./EGW";

function Root() {
    let theme = localStorage.getItem("theme");
    let light = false;
    if (theme === "light" || window.matchMedia("(prefers-color-scheme: light)").matches) {
        document.documentElement.classList.add("light");
        light = true;
    }

    return <div id="view">
        <BrowserRouter>
            <ScrollToTop />
            <div id="top-bar">
                <Routes>
                    <Route path="/:path" element={
                        <Link to="/">
                            <button id="home">🏠</button>
                        </Link>} />
                </Routes>
                <button id="theme-switcher" onClick={() => switchTheme()} aria-label="switch theme">
                    {light ? "🌝" : "🌚"}
                </button>
            </div>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/egw" element={<EGW />} />
                <Route path="*" element={<>
                    <h1>Uh oh, a 404</h1>
                    <p>This page does not exist or was removed.</p>
                </>} />
            </Routes>
        </BrowserRouter>        
    </div>
}

function switchTheme() {
    let button = document.querySelector("#theme-switcher");
    let states = [ "🌚", "🌑", "🌒", "🌓", "🌔", "🌕", "🌝"];
    if (document.documentElement.classList.toggle("light")) {
        for (let i=0; i < states.length; i++) {
            setTimeout(() => { 
                button.innerHTML = states[i];
            }, 200 * i);
        }
        localStorage.setItem("theme", "light");
    } else {
        for (let i=1; i <= states.length; i++) {
            setTimeout(() => { 
                button.innerHTML = states[states.length - i];
            }, 200 * (i-1)); // subtract 1 to compensate for starting at 1
        }
        localStorage.setItem("theme", "dark");
    }
}

export default Root;
