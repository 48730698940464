import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./home.scss";

function Home() {
    const navigate = useNavigate();
    function goto(url: string) {
        if (url.includes("https")) {
            window.open(url, "_blank");
        } else { // assume it's linking to a page on the site itself
            navigate(url);
        }
    }
    
    const [music, setMusic] = useState("fetching music...") as [string, Function];
    useEffect(() => {
        fetch("https://lastfm.lieuweberg.workers.dev/")
            .then(async res => {
                setMusic(await res.text());
            }).catch(err => {
                setMusic("<i>error fetching</i>");
                console.error(err);
            })
    }, [])

    return <div className="home">
        <div className="container">
            <h1>lieuwe's corner of the internet</h1>
            <p>hi, my name is lieuwe (dutch: [liʊe]) and I like making software and playing action games</p>
            <p>here are a few of my links:</p>

            <ul>
                <li><a href="https://twitter.com/lieuwe_berg">twitter</a></li>
                <li><a href="https://github.com/lieuweberg">github</a></li>
                <li><a href="https://discord.gg/DRcFmb3">discord</a></li>
                <li><a href="https://www.patreon.com/member_count">patreon</a></li>
            </ul>
            <br/>
            <p id="music" dangerouslySetInnerHTML={{ __html: "🎵 " + music }}></p>
        </div>
        <div id="projects">
            <div onClick={() => goto("https://richdestiny.app")} tabIndex={0}>
                <img src="./richdestiny.png" alt="rich destiny website screenshot"/>
                <h3>rich-destiny</h3>
                <p>A discord rich presence tool for destiny 2. Forwards your
                    destiny activity to your discord status. Runs locally in the background.</p>
            </div>
            <div onClick={() => goto("/egw")} tabIndex={0}>
                <img className="top" src="./egw.png" alt="weekly embed screenshot"/>
                <h3>Epic Games Weekly</h3>
                <p>This discord bot sends an embed every week containing that week's free games
                    on the Epic Games Store with links and dates.</p>
            </div>
            <div onClick={() => goto("https://membercount.net")} tabIndex={0}>
                <img className="top" src="./membercount.jpg" alt="counters and commands screenshot"/>
                <h3>Member Count</h3>
                <p>Updates 'counters' (locked channels) in your discord server with some of
                    your server's statistics.</p>
            </div>
        </div>
    </div>
}

export default Home;